/* General Styles */

.btn:disabled {
  cursor: not-allowed;
}
.btn-noborder {
  border-width: 0;
}

/* Toast Styles */
.toast-outer-container {
  position: relative;
}
  .toast-outer-container > .toast-container {
    position: absolute; top: 0; right: 0;
  }
  .toast .toast-image {
    height: 20px;
    width: 20px;
  }

/* List Group Styles */
.list-group-nohover .list-group-item:hover {
  background-color: #32383e;
}
.list-group-item-nohover:hover {
  background-color: #32383e;
}

.list-group-borderless .list-group-item:first-child {
  border-top-width: 0;
}
.list-group-borderless .list-group-item:last-child {
  border-bottom-width: 0;
}
.list-group-item-borderless {
  border-top-width: 0;
  border-bottom-width: 0;
}
.list-group-horizontal.list-group-borderless .list-group-item {
  border-width: 0;
}

/* View Activity Page */
#campaign-activity .list-group-item:hover {
  background-color: #32383e;
}
#campaign-activity .character-image {
  height: 40px;
  width: 40px;
}

/* View Settings Page */
#campaign-settings .list-group-header:hover {
  background-color: #32383e;
}

form.settings-form {
  width: 100%
}

/* View Character Page */
.character-portrait {
  width: 60px;
  height: 60px;
  margin-right: 15px;
  z-index: 1;
}

.character-avatar {
  width: 100%;
  height: 100%;
  border: 2px solid #4ab9db;
  background-size: cover;
}

#character-history .accordion .card:first-of-type {
  border-radius: 0;
}

#character-history .history-section-toggle, #character-history .encounter-header {
  cursor: pointer;
  font-weight: 400;
  color: #fff;
}

#character-history .list-group-item:hover {
  background-color: #32383e;
}

.custom-item-form button[type=submit] {
  margin-right: 1.25rem;
}

